<template>
  <div>
    <van-config-provider :theme-vars="themeVars">
      <van-tabbar v-model="active" route v-if="isShow" @change="onChange">
        <van-tabbar-item to="/mobile-home" icon="home-o">首页</van-tabbar-item>
        <van-tabbar-item to="/mobile-gametype" icon="guide-o"
          >账号</van-tabbar-item
        >
      </van-tabbar>
    </van-config-provider>
    <router-view></router-view>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs, computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "App",
  components: {},
  setup() {
    const store = useStore();
    const data = reactive({
      active: 0,
      themeVars: { "tabbar-height": "50px", "tabbar-item-icon-size": "30px" },
    });
    const isShow = computed(() => store.getters["getIsShow"]);
    onMounted(() => {});
    function onChange(active) {
      data.active = active;
    }
    return {
      ...toRefs(data),
      onChange,
      isShow,
    };
  },
};
</script>

<style></style>
