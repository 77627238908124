import { createStore } from "vuex";

export default createStore({
  state: {
    tagsList: [],
    collapse: true,
    token: "",
    isShow: true,
  },
  getters: {
    getIsShow(state) {
      return state.isShow;
    },
  },
  //methods
  mutations: {
    // 侧边栏折叠
    handleCollapse(state, data) {
      state.collapse = data;
    },
    isShowTabbar(state, value) {
      state.isShow = value;
    },
  },
  actions: {},
  modules: {},
});
