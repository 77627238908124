let { createWebHashHistory, createRouter } = require('vue-router')
import { useRouter } from 'vue-router'
function isMobile() {
  return /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent)
}
var isCurrent = true
const routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      ismobile: true,
      admin: false,
      pc: true,
    },
  },
  {
    path: '/admin-dashboard',
    name: 'admin-dashboard',
    component: () => import('@/views/admin-dashboard/admin-dashboard.vue'),
    ismobile: false,
    admin: true,
    pc: false,
    children: [
      {
        path: '/admin-home',
        name: 'admin-home',
        meta: {
          title: '系统首页',
          ismobile: true,
          admin: true,
          pc: false,
        },
        component: () => import('@/views/admin-home/admin-home.vue'),
      },
      {
        path: '/admin-front',
        name: 'admin-front',
        meta: {
          title: '内容管理',
          ismobile: true,
          admin: false,
          pc: false,
        },
        component: () => import('@/views/admin-front/admin-front.vue'),
      },
      {
        path: '/admin-config',
        name: 'admin-config',
        meta: {
          title: '配置管理',
          ismobile: true,
          admin: false,
          pc: false,
        },
        component: () => import('@/views/admin-config/admin-config.vue'),
      },
      {
        path: '/admin-gametype',
        name: 'admin-gametype',
        meta: {
          title: '游戏类别',
          ismobile: true,
          admin: false,
          pc: false,
        },
        component: () => import('@/views/admin-gametype/admin-gametype.vue'),
      },
      {
        path: '/admin-notice',
        name: 'admin-notice',
        meta: {
          title: '通知栏',
          ismobile: true,
          admin: false,
          pc: false,
        },
        component: () => import('@/views/admin-notice/admin-notice.vue'),
      },
      {
        path: '/admin-user',
        name: 'admin-user',
        meta: {
          title: '用户管理',
          ismobile: true,
          admin: false,
          pc: false,
        },
        component: () => import('@/views/admin-user/admin-user.vue'),
      },

      {
        path: '/admin-ownerInfo',
        name: 'admin-ownerInfo',
        meta: {
          title: '个人中心',
          ismobile: true,
          admin: false,
          pc: false,
        },
        component: () => import('@/views/admin-ownerInfo/admin-ownerInfo.vue'),
      },
      {
        path: '/admin-news',
        name: 'admin-news',
        meta: {
          title: '资讯管理',
          ismobile: true,
          admin: false,
          pc: false,
        },
        component: () => import('@/views/admin-news/admin-news.vue'),
      },
      {
        path: '/admin-recyclebin',
        name: 'admin-recyclebin',
        meta: {
          title: '回收站',
          ismobile: true,
          admin: false,
          pc: false,
        },
        component: () => import('@/views/admin-recyclebin/admin-recyclebin.vue'),
      },
      {
        path: '/admin-banner',
        name: 'admin-banner',
        meta: {
          title: '广告位',
          ismobile: true,
          admin: false,
          pc: false,
        },
        component: () => import('@/views/admin-banner/admin-banner.vue'),
      },
      {
        path: '/admin-swiper',
        name: 'admin-swiper',
        meta: {
          title: '轮播图',
          ismobile: true,
          admin: false,
          pc: false,
        },
        component: () => import('@/views/admin-swiper/admin-swiper.vue'),
      },
    ],
  },
  {
    path: '/admin-login',
    name: 'admin-login',
    meta: {
      title: '登录',
      ismobile: false,
      admin: true,
      pc: false,
    },
    component: () => import('@/views/admin-login/admin-login.vue'),
  },
  {
    path: '/front-home',
    name: 'front-home',
    meta: {
      title: '网站首页',
      ismobile: true,
      admin: false,
      pc: false,
    },
    component: () => import('@/views/webfront/front-home/front-home.vue'),
  },
  {
    path: '/front-list',
    name: 'front-list',
    meta: {
      title: '列表页',
      ismobile: true,
      admin: false,
      pc: false,
    },
    component: () => import('@/views/webfront/front-list/front-list.vue'),
  },
  {
    path: '/list-detail',
    name: 'list-detail',
    meta: {
      title: '列表详情页',
      ismobile: true,
      admin: false,
      pc: false,
    },
    component: () => import('@/views/webfront/list-detail/list-detail.vue'),
  },
  {
    path: '/front-news',
    name: 'front-news',
    meta: { title: '资讯列表', ismobile: true, admin: false, pc: false },
    component: () => import('@/views/webfront/front-news/front-news.vue'),
  },
  {
    path: '/front-news-detail',
    name: 'front-news-detail',
    meta: {
      title: '列表详情页',
      ismobile: true,
      admin: false,
      pc: false,
    },
    component: () => import('@/views/webfront/front-news-detail/front-news-detail.vue'),
  },
  {
    path: '/mobile-home',
    name: 'mobile-home',
    meta: { title: '网站手机端首页', ismobile: false, admin: false, pc: false },
    component: () => import('@/views/mobile/mobile-home/mobile-home.vue'),
  },
  {
    path: '/mobile-gametype',
    name: 'mobile-gametype',
    meta: { title: '游戏类别', ismobile: false, admin: false, pc: false },
    component: () => import('@/views/mobile/mobile-gametype/mobile-gametype.vue'),
  },
  {
    path: '/mobile-list',
    name: 'mobile-list',
    meta: { title: '账号列表', ismobile: false, admin: false, pc: false },
    component: () => import('@/views/mobile/mobile-list/mobile-list.vue'),
  },
  {
    path: '/mobile-list-detail',
    name: 'mobile-list-detail',
    meta: { title: '账号详情', ismobile: false, admin: false, pc: false },
    component: () => import('@/views/mobile/mobile-list-detail/mobile-list-detail.vue'),
  },
]
// 路由器
const router = createRouter({
  history: createWebHashHistory(), // HTML5模式
  routes,
})
const token = localStorage.getItem('token')
let _isMobile = isMobile()
router.beforeEach((to, from, next) => {
  if (_isMobile) {
    if (to.meta.ismobile) {
      next({ path: '/mobile-home' })
    } else {
      next()
    }
  } else {
    if (to.meta.pc) {
      if (token || !token) {
        next({ path: '/front-home' })
      } else {
        next()
      }
    } else {
      if (to.meta.admin && isCurrent) {
        isCurrent = false
        if (!token) {
          next({ path: '/admin-login' })
        } else {
          next()
        }
      } else {
        next()
      }
    }
  }
})
router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
