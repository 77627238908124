import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "lib-flexible/flexible";
import {
  NoticeBar,
  Swipe,
  SwipeItem,
  NavBar,
  Button,
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  Search,
  List,
  Cell,
  ConfigProvider,
  Grid,
  GridItem,
  Popup,
  Sidebar,
  SidebarItem,
  Uploader,
  Tabbar,
  TabbarItem,
  ImagePreview,
  Pagination,
  Icon,
} from "vant";
import "vant/lib/index.css";

import * as ElIconModules from "@element-plus/icons-vue";
import md5 from "js-md5";
let app = createApp(App);
app.config.globalProperties.$md5 = md5; //Vue.prototype
app.use(router);
app.use(ElementPlus);
app.use(store);
app.use(NoticeBar);
app.use(Swipe);
app.use(SwipeItem);
app.use(NavBar);
app.use(Button);
app.use(ActionBar);
app.use(ActionBarIcon);
app.use(ActionBarButton);
app.use(Search);
app.use(List);
app.use(Cell);
app.use(ConfigProvider);
app.use(Grid);
app.use(GridItem);
app.use(Popup);
app.use(Sidebar);
app.use(SidebarItem);
app.use(Uploader);
app.use(Tabbar);
app.use(TabbarItem);
app.use(ImagePreview);
app.use(Pagination);
app.use(Icon)

Object.keys(ElIconModules).forEach(function (key) {
  app.component(ElIconModules[key].name, ElIconModules[key]);
});
app.mount("#app");
